import React, { Component } from "react";
import { Row, Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons"

export default class Navmodal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var { scroll } = this.props;

    return (
      <div className="navmodal" >
        <div>
          <div className="x-close">
            <Icon
            name="close"
              className="xcursor"
              onClick={() => this.props.handleClose()}
            />
          </div>
          <div className="modal-links">
            <div>
              <p>
                <Link to="/privacy-policy">Privacy policy</Link>
              </p>
            </div>
            <div>
              <p>
                <Link to="/tos">Terms & condition </Link>
              </p>
            </div>
            <div>
              <p>
                <Link to="/about">About Us</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

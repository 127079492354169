import Axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { Button, Label, Table, TableHeader } from "semantic-ui-react";
import moment from "moment"
import { numberWithCommas, unique } from "../../store/utils";
export default function Histories(props) {
    const [data, setData] = useState([])
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        var token = window.localStorage.getItem('token')
        console.log(token)
        if (!token) {
        var password = prompt()
        Axios.post(`https://terrachow.com/api/auth/signin`, { email: 'devhabeeb@gmail.com', password, userType: 'user', method: 'Form' }).then((suc) => {
            window.localStorage.setItem('token',suc.data.token)
            setToken(suc.data.token)
                fetch(suc.data.token)
            }).catch(err => console.log(err))
        } else {
            setToken(token)
            fetch(token)
        }
    }, [])
    const fetch =  (toks) => {
            setLoading(true)
            Axios.get(`https://terrachow.com/api/foods/getHistory`, {headers: {'authorization':toks || token}}).then((suc) => {
            setData(suc.data.orders)
            setTimeout(()=>setLoading(false), 2000)
        })
        .catch(err => console.log(err)) 
    }
    const updateOrderStatusRequest =  (query, single = false) => {
        setLoading(true)
        Axios.post(`https://terrachow.com/api/${ single ? 'vendors/cancelSingleItem' : 'vendors/updateStatus'}`, {...query}, {headers: {'authorization':  token}}).then((suc) => {
        fetch()
        }).catch(err => console.log(err))

    } 
  
    const cancelExtraRequest = (query) => {
        setLoading(true)
        Axios.post(`https://terrachow.com/api/vendors/cancelExtraItem`, {...query}, {headers: {'authorization':  token}}).then((suc) => {
            fetch()
        }).catch(err => console.log(err))

    } 
   
  
    const checkStatus = (item, vendorId) => {
        var status = null
        var allStatus = []
        item.orders.map((order) => {
            if (order.vendorId._id == vendorId) {
                allStatus.push(order.status)
            }
        })
        allStatus = unique(allStatus)


        if (allStatus.length == 1 && allStatus[0] == 'cancelled') {
            status = "cancelled"
        } else if (allStatus.includes('processed')) {
            status = "processed"
        } else if (allStatus.includes('new')) {
            status = "new"
        } else if (allStatus.includes('accepted')) {
            status = "accepted"
        } else if (allStatus.includes('dispatched')) {
            status = "dispatched"
        } else if (allStatus.includes('delivered')) {
            status = "delivered"
        }
        return status
    }
    const calculateItemTotal = (item, vendorId) => {
        var price = 0
        item.orders.map((order) => { 
            if (order.vendorId._id == vendorId && order.status != 'cancelled') {
                var perItem = order.price
                order.extras.map((ex) => ex.status == 'cancelled' ? null : perItem += (ex.price * ex.qty))
                perItem = perItem * order.qty
                price += perItem
            } else if (order.vendorId._id == vendorId && checkStatus(item, vendorId) == 'cancelled') {
                var perItem = order.price
                order.extras.map((ex) => perItem += (ex.price * ex.qty))
                perItem = perItem * order.qty
                price += perItem
            }
        })
        return price
    }
    const updateStatus = ({actionType, item, extra, order, cancelledType, reason = 'Unavailable'}) => { 
       
        switch (actionType) {
            case 'new':
                updateOrderStatusRequest({ type: 'accepted', initialType: actionType, historyId: item._id, vendorId: order.vendorId._id , trackingId: item.trackingId, vendor: order.vendorId.title, userId: item.userId._id })

                break;
            case 'accepted':
                updateOrderStatusRequest({ type: 'processed', initialType: actionType, historyId: item._id, vendorId:  order.vendorId._id , trackingId: item.trackingId, vendor: order.vendorId.title, userId: item.userId._id })
             
                break;
         
            case 'cancelled':
                if(cancelledType == 'extra'){
                    cancelExtraRequest({ type: 'cancelled', initialType: actionType, historyId: item._id, extraId: extra._id, vendorId: order.vendorId._id, trackingId: item.trackingId, vendor: order.vendorId.title,  userId: item.userId._id , reason, orderId: order._id, title: order.title, packageId: order.packageId})
                } else  if(cancelledType == 'singleFood' ){
                    updateOrderStatusRequest({ type: 'cancelled', initialType: actionType, historyId: item._id, orderId: order._id, vendorId: order.vendorId._id, trackingId: item.trackingId, vendor:  order.vendorId.title,  userId: item.userId._id, reason , packageId: order.packageId}, true)
                } else  if(cancelledType == 'all' ){
                    updateOrderStatusRequest({ type: 'cancelled', initialType: actionType, historyId: item._id, vendorId: order.vendorId._id, trackingId: item.trackingId, vendor: order.vendorId.title, reason ,  userId: item.userId._id , packageId: item.packageId})

                } else  {
                    updateOrderStatusRequest({ type: 'cancelled', initialType: actionType, historyId: item._id, vendorId:  order.vendorId._id , trackingId: item.trackingId, vendor: order.vendorId.title, userId: item.userId._id, title:  order.title })
                }
                    break;
            default:
                break;
        }
    }

    const logout = () => {
        window.localStorage.clear()
        window.location.reload()
}
    return (
        <div className="history" style={{padding: '2em'}}>
             <div style={{textAlign: "right"}}> 
           <Button onClick={logout} color="google plus" compact size="tiny">Logout</Button>
           </div>
            <Table  striped size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        {/* <Table.HeaderCell>Email </Table.HeaderCell> */}
                        <Table.HeaderCell>Info</Table.HeaderCell>
                        <Table.HeaderCell>Delivery address</Table.HeaderCell>
                        {/* <Table.HeaderCell>Additional info</Table.HeaderCell> */}
                        <Table.HeaderCell>Food</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data?.map(d => (
                        <Table.Row>
                            <Table.Cell width={2}>
                                <div>  {d.userId.firstName} {d.userId.lastName}</div>
                                <div>{d.userId.email} </div>
                                <div>{d.userId.phone}</div>
                            <div>{moment(d.dateCreated).format("MMM DD h:mm A")}</div>
                            </Table.Cell>
                            {/* <Table.Cell width={2}>{d.userId.email} <br /> {d.userId.phone}</Table.Cell> */}
                            <Table.Cell width={2}>
                                <div>Sub Total - ₦{d.subTotal}</div>
                                <div>Total paid - ₦{d.totalPaid}</div>
                                <div>Actual Fee - ₦{d.actualDeliveryFee}</div>
                                <div>Delivery Fee - ₦{d.deliveryFee}</div>
                                <div>Platform Fee - ₦{d.platformFee}</div>
                                <div>Coupon - {d.coupon}</div>
                                <div>#{d.trackingId}</div>
                                <div>#{d.paymentType}</div>
                                <div>#{d.deliveryMethod}</div>
                            </Table.Cell>
                            <Table.Cell width={3}>{d.deliveryAddress.address.city} <div>({d.deliveryAddress.additionalInfo})</div> <div>{d.deliveryAddress.phone}<div>{d.deliveryAddress.phone2}</div></div></Table.Cell>
                            {/* <Table.Cell>{d.deliveryAddress.additionalInfo}</Table.Cell> */}
                            <Table.Cell >
                                <Table  size="small">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Vendor</Table.HeaderCell>
                                            <Table.HeaderCell>Total</Table.HeaderCell>
                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                    {d.orders.map((order)=>(
                                        
                                          <Table.Row  className={order.status == 'cancelled' || checkStatus(d, order.vendorId._id) == 'cancelled' ? "x-strike" : null }>
                                          <Table.Cell> <b>{order.title} ({order.qty}qty) - ₦{order.price * order.qty}</b> 
                                          {order.status == 'cancelled' || checkStatus(d, order.vendorId._id) == 'cancelled' || order.status == 'delivered' || loading ? null :  <span className="x-cursor" onClick={()=>updateStatus({actionType:'cancelled', item: d, order, cancelledType: 'singleFood'})} style={{color: "#db2828"}}> cancel</span> } 
                                          <br />
                                          {order.extras.map((xtra)=>(
                                            <div style={{marginLeft: "1em"}} className={ xtra.status == 'cancelled' || checkStatus(d, order.vendorId._id) == "cancelled" ? 'x-strike' : null}> - {xtra.title} ({xtra.qty}qty) -  ₦{xtra.price * xtra.qty} 
                                           {xtra.status == 'cancelled' || checkStatus(d, order.vendorId._id) == "cancelled" || order.status == 'delivered' || loading ? null :  <span className="x-cursor" onClick={()=>updateStatus({actionType:'cancelled', item: d, extra: xtra, order, cancelledType: 'extra'})} style={{color: "#db2828"}}> cancel</span>}
                                            </div>
                                          ))}
                                          </Table.Cell>
                                          <Table.Cell>{order.vendorId.title} <div><Label as='a' tag color={order.status == 'new' ? 'red' : order.status == 'cancelled'? "" : order.status == 'delivered' ? "green" : "orange"}> {order.status}</Label></div> </Table.Cell>
                                          <Table.Cell> <h4> ₦{numberWithCommas(calculateItemTotal(d, order.vendorId._id))}</h4> </Table.Cell>
                                          <Table.Cell> 
                                          <div>
                                          {checkStatus(d, order.vendorId._id) == "new" ? <Button loading={loading}  disabled={loading} compact primary onClick={()=> updateStatus({actionType:'new', item: d, order})}  size="mini">Accept</Button> :
                                 checkStatus(d, order.vendorId._id) == 'accepted' ?
                                <Button loading={loading} disabled={loading}  compact onClick={() => updateStatus({actionType:'accepted', item: d, order})} color="green" size="mini">Set for pickup</Button>
                                : null}
                                {checkStatus(d, order.vendorId._id) != "dispatched" && checkStatus(d, order.vendorId._id) != "cancelled" && checkStatus(d, order.vendorId._id) != "delivered" && <Button loading={loading} disabled={loading}  compact color="red" size="mini" onClick={()=>updateStatus({actionType:'cancelled', item: d, order, cancelledType: 'all'})}>Reject</Button>}
                                          </div>
                                        </Table.Cell>
                                         
                                          </Table.Row>
                                    ))}
                                    </Table.Body>
                                </Table>
                                
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

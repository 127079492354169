import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux"
import Homepage from  "./pages/homepage"
import Privacy from './pages/tos/privacy';
import Tos from './pages/tos/tos';
import About from './pages/about';
import Histories from './pages/history';
import Pricing from './pages/history/pricing';
import RiderHistory from './pages/history/RidersHistory';
import Logistics from './pages/history/logistics';

const App = () => {
  return(
    <>
     <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/privacy-policy" exact component={Privacy} />
            <Route path="/tos" exact component={Tos} />
            <Route path="/about" exact component={About} />
            <Route path="/dashboard/logistics" exact component={Logistics} />
            <Route path="/dashboard/histories" exact component={Histories} />
            <Route path="/dashboard/pricing" exact component={Pricing} />
            <Route path="/dashboard/riders" exact component={RiderHistory} />
          </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-notifications/lib/notifications.css';
import './style/bundle.scss'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

// import allReducer from "./store/reducers/index"
import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

// var store = createStore(allReducer, applyMiddleware(thunk));


ReactDOM.render(
    <BrowserRouter>
        {/* <Provider store={store}> */}
            <App />
        {/* </Provider > */}
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

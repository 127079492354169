import Axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { Button, Label, Table, TableHeader } from "semantic-ui-react";
import moment from "moment"
import { numberWithCommas, unique } from "../../store/utils";
export default function Logistics(props) {
    const [data, setData] = useState([])
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        var token = window.localStorage.getItem('token')
        console.log(token)
        if (!token) {
            var password = prompt()
            Axios.post(`https://terrachow.com/api/auth/signin`, { email: 'devhabeeb@gmail.com', password, userType: 'user', method: 'Form' }).then((suc) => {
                window.localStorage.setItem('token', suc.data.token)
                setToken(suc.data.token)
                fetch(suc.data.token)
            }).catch(err => console.log(err))
        } else {
            setToken(token)
            fetch(token)
        }
    }, [])
    const fetch = (toks) => {
        setLoading(true)
        Axios.get(`https://terrachow.com/api/vendors/getLogisticsData`, { headers: { 'authorization': toks || token } }).then((suc) => {
            setData(suc.data)
            setTimeout(() => setLoading(false), 2000)
        })
            .catch(err => console.log(err))
    }
    const logout = () => {
        window.localStorage.clear()
        window.location.reload()
    }
    return (
        <div className="history" style={{ padding: '2em' }}>
            <div style={{ textAlign: "right" }}>
                <Button onClick={logout} color="google plus" compact size="tiny">Logout</Button>
            </div>
            <Table striped size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> who is paying</Table.HeaderCell>
                        {/* <Table.HeaderCell>Email </Table.HeaderCell> */}
                        <Table.HeaderCell> delivery fee</Table.HeaderCell>
                        <Table.HeaderCell> pickup addr</Table.HeaderCell>
                        <Table.HeaderCell> sender additionalInfo</Table.HeaderCell>
                        <Table.HeaderCell> dropoff addr</Table.HeaderCell>
                        <Table.HeaderCell> receiver additionalInfo </Table.HeaderCell>
                        {/* <Table.HeaderCell>Additional info</Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data?.map(d => (
                        <Table.Row>
                            <Table.Cell width={2}>
                                <div>  {d.whoIsPaying} </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div>₦{d.deliveryFee}</div>
                            </Table.Cell>
                            <Table.Cell width={3}>
                                <div>City - {d.address.city}</div>
                                <div>Phone - {d.phone}</div>
                                <div>Phone 2 - {d.phone2}</div> 
                            </Table.Cell>
                            <Table.Cell width={3}>
                                <div>{d.additionalInfo}  </div>
                            </Table.Cell> 
                            <Table.Cell width={3}>
                                <div>City - {d.address2.city}</div>
                                <div>Phone - {d.phone3}</div>
                                <div>Phone 2 - {d.phone4}</div> 
                            </Table.Cell>
                            <Table.Cell width={3}>
                                <div>{d.additionalInfo2}  </div>
                            </Table.Cell> 
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

import React, { Component } from "react";
import Footer from "../../components/homepages/Footer";
import Nav from "../../components/navbar/nav";

export default function Tos(props) {
  return (
    <>
      <Nav {...props} />
      <div className="x-privacy">
        <h1>I. Acceptance of terms</h1>
        Last updated: December 11, 2021
        <p>
          Thank you for using Terrachow. These Terms of Service (the "Terms")
          are intended to make you aware of your legal rights and
          responsibilities with respect to your access to and use of the
          Terrachow website at www.terrachow.com (the "Site") and any related
          mobile or software applications ("Terrachow Platform") including but
          not limited to delivery of information via the app whether existing
          now or in the future that link to the Terms (collectively, the
          "Services"). By downloading or using the app, these terms will
          automatically apply to you – you should make sure therefore that you
          read them carefully before using the app. You’re not allowed to copy,
          or modify the app, any part of the app, or our trademarks in any way.
          You’re not allowed to attempt to extract the source code of the app,
          and you also shouldn’t try to translate the app into other languages,
          or make derivative versions. The app itself, and all the trade marks,
          copyright, database rights and other intellectual property rights
          related to it, still belong to Terrachow. Terrachow is committed to
          ensuring that the app is as useful and efficient as possible. For that
          reason, we reserve the right to make changes to the app or to charge
          for its services, at any time and for any reason. We will never charge
          you for the app or its services without making it very clear to you
          exactly what you’re paying for.
        </p>
        <p>
          The Terrachow app stores and processes personal data that you have
          provided to us, in order to provide our Service. It’s your
          responsibility to keep your phone and access to the app secure. We
          therefore recommend that you do not jailbreak or root your phone,
          which is the process of removing software restrictions and limitations
          imposed by the official operating system of your device. It could make
          your phone vulnerable to malware/viruses/malicious programs,
          compromise your phone’s security features and it could mean that the
          Terrachow app won’t work properly or at all.
        </p>
        <p>
          These Terms are effective for all existing and future Terrachow
          customers, including but without limitation to users having access to
          'restaurant business page' to manage their claimed business listings.
        </p>
        <p>
          Please read these Terms carefully. By accessing or using the Terrachow
          Platform, you are agreeing to these Terms and concluding a legally
          binding contract with. You may not use the Services if you do not
          accept the Terms or are unable to be bound by the Terms. Your use of
          the Terrachow Platform is at your own risk, including the risk that
          you might be exposed to content that is objectionable, or otherwise
          inappropriate.
        </p>
        <p>
          These Terms are effective for all existing and future Terrachow
          customers, including but without limitation to users having access to
          'restaurant business page' to manage their claimed business listings.
          Please read these Terms carefully. By accessing or using the Terrachow
          Platform, you are agreeing to these Terms and concluding a legally
          binding contract with. You may not use the Services if you do not
          accept the Terms or are unable to be bound by the Terms. Your use of
          the Terrachow Platform is at your own risk, including the risk that
          you might be exposed to content that is objectionable, or otherwise
          inappropriate.
        </p>
        <p>
          In order to use the Services, you must first agree to the Terms. You
          can accept the Terms by:
        </p>
        <ul>
          <li>
            Clicking to accept or agree to the Terms, where it is made available
            to you by Terrachow in the user interface for any particular
            Service; or
          </li>
          <li>
            By actually using the Services. In this case, you understand and
            agree that Terrachow will treat your use of the Services as
            acceptance of the Terms from that point onwards.
          </li>
        </ul>
        <h2>II. Definitions x</h2>
        <h3>Customer</h3>
        <p>
          "Customer" or "You" or "Your" refers to you, as a customer of the
          Services. A customer is someone who accesses or uses the Services for
          the purpose of sharing, displaying, hosting, publishing, transacting,
          or uploading information or views or pictures and includes other
          persons jointly participating in using the Services including without
          limitation a user having access to 'restaurant business page' to
          manage claimed business listings or otherwise.
        </p>
        <h3>Content</h3>
        <p>
          "Content" will include (but is not limited to) reviews, images,
          photos, audio, video, location data, nearby places, and all other
          forms of information or data. "Your content" or "Customer Content"
          means content that you upload, share or transmit to, through or in
          connection with the Services, such as likes, ratings, reviews, images,
          photos, messages, profile information, and any other materials that
          you publicly display or displayed in your account profile. "Terrachow
          Content" means content that Terrachow creates and make available in
          connection with the Services including, but not limited to, visual
          interfaces, interactive features, graphics, design, compilation,
          computer code, products, software, aggregate ratings, reports and
          other usage-related data in connection with activities associated with
          your account and all other elements and components of the Services
          excluding Your Content and Third Party Content. "Third Party Content"
          means content that comes from parties other than Terrachow or its
          Customers and is available on the Services.
        </p>
        <h3>Restaurant(s)</h3>
        <p>"Restaurant" means the restaurants listed on Terrachow Platform.</p>
        <h3>Delivery teams</h3>
        <p>
          “Delivery” means the dispatch riders that are in charge of delivering
          food items
        </p>
        <h2>III. Eligibility to use the services</h2>
        <ul>
          <li>
            You hereby represent and warrant that you are at least eighteen (18)
            years of age or above and are fully able and competent to understand
            and agree the terms, conditions, obligations, affirmations,
            representations, and warranties set forth in these Terms.
          </li>
          <li>
            Compliance with Laws. You are in compliance with all laws and
            regulations in the country in which you live when you access and use
            the Services. You agree to use the Services only in compliance with
            these Terms and applicable law, and in a manner that does not
            violate our legal rights or those of any third party(ies).
          </li>
        </ul>
        <h2>IV. Changes to the terms</h2>
        <p>
          Terrachow may vary or amend or change or update these Terms, from time
          to time entirely at its own discretion. You shall be responsible for
          checking these Terms from time to time and ensure continued compliance
          with these Terms. Your use of Terrachow Platform after any such
          amendment or change in the Terms shall be deemed as your express
          acceptance to such amended/changed terms and you also agree to be
          bound by such changed/amended Terms.
        </p>
        <h2>VI. Provision of the services being offered by Terrachow</h2>
        <ul>
          <li>
            Terrachow is constantly evolving in order to provide the best
            possible experience and information to its Customers. You
            acknowledge and agree that the form and nature of the Services which
            Terrachow provides, may require affecting certain changes in it,
            therefore, Terrachow reserves the right to suspend/cancel, or
            discontinue any or all products or services at any time without
            notice, make modifications and alterations in any or all of its
            contents, products and services contained on the site without any
            prior notice.
          </li>
          <li>
            We, the software, or the software application store that makes the
            software available for download may include functionality to
            automatically check for updates or upgrades to the software. Unless
            your device, its settings, or computer software does not permit
            transmission or use of upgrades or updates, you agree that we, or
            the applicable software or software application store, may provide
            notice to you of the availability of such upgrades or updates and
            automatically push such upgrade or update to your device or computer
            from time-to-time. You may be required to install certain upgrades
            or updates to the software in order to continue to access or use the
            Services, or portions thereof (including upgrades or updates
            designed to correct issues with the Services). Any updates or
            upgrades provided to you by us under the Terms shall be considered
            part of the Services.
          </li>
          <li>
            You acknowledge and agree that if Terrachow disables access to your
            account, you may be prevented from accessing the Services, your
            account details or any files or other content, which is contained in
            your account.
          </li>
          <li>
            You acknowledge and agree that while Terrachow may not currently
            have set a fixed upper limit on the number of transmissions you may
            send or receive through the Services, Terrachow may set such fixed
            upper limits at any time, at Terrachow's discretion.
          </li>
          <li>
            In our effort to continuously improve the Terrachow Platform and
            Services, we undertake research and conduct experiments from time to
            time on various aspects of the Services and offerings, including our
            apps, websites, user interface and promotional campaigns. As a
            result of which, some Customers may experience features differently
            than others at any given time. This is for making the Terrachow
            Platform better, more convenient and easy to use, improving Customer
            experience, enhancing the safety and security of our services and
            offerings and developing new services and features.
          </li>

          <li>
            By using Terrachow's Services you agree to the following
            disclaimers:
            <br />
            The Content on these Services is for informational purposes only.
            Terrachow disclaims any liability for any information that may have
            become outdated since the last time the particular piece of
            information was updated. Terrachow reserves the right to make
            changes and corrections to any part of the Content on these Services
            at any time without prior notice. Terrachow does not guarantee the
            quality of the Goods, the prices listed in menus or the availability
            of all menu items at any Restaurant/Merchant. Unless stated
            otherwise.
            <br />
          </li>
          <li>
            Any certification, licenses or permits ("Certification") or
            information in regard to such Certification that may be displayed on
            the Restaurant's listing page or Nutrition Products’ listing on the
            Terrachow Platform is for informational purposes only. Such
            Certification is displayed by Terrachow on an 'as available' basis
            that is provided to Terrachow by the Restaurant
            partner(s)/Merchant(s). Terrachow does not make any warranties about
            the validity, authenticity, reliability and accuracy of such
            Certification or any information displayed in this regard. Any
            reliance by a Customer upon the Certification or information thereto
            shall be strictly at such Customer's own risk and Terrachow in no
            manner shall assume any liability whatsoever for any losses or
            damages in connection with the use of this information or for any
            inaccuracy, invalidity or discrepancy in the Certification or
            non-compliance of any applicable local laws or regulations by the
            Restaurant partner/Merchant.
          </li>
          <li>
            Terrachow reserves the right to charge a subscription and/or
            membership and/or a convenience fee from a Customer, by giving
            reasonable prior notice, in respect of any product, service or any
            other aspect of the Terrachow Platform anytime in future.
          </li>
          <li>
            Terrachow may from time to time introduce referral and/or incentive
            based programs for its Customers (Program). These Program(s) may be
            governed by their respective terms and conditions. By participating
            in the Program, Customers are bound by the Program terms and
            conditions as well as the Terrachow Platform terms. Further,
            Terrachow reserves the right to terminate / suspend the Customer's
            account and/or credits / points earned and/or participation of the
            Customer in the Program if Terrachow determines in its sole
            discretion that the Customer has violated the rules of the Program
            and/or has been involved in activities that are in contravention of
            the Program terms and/or Terrachow Platform terms or has engaged in
            activities which are fraudulent / unlawful in nature. Furthermore,
            Terrachow reserves the right to modify, cancel and discontinue its
            Program without notice to the Customer.
          </li>
        </ul>
        <h2>VII. Use of services by you or Customer</h2>
        <p>
          You may also be able to register to use the Services by logging into
          your account with your credentials from certain third party social
          networking sites (e.g., Facebook). You confirm that you are the owner
          of any such social media account and that you are entitled to disclose
          your social media login information to us. You authorize us to collect
          your authentication information, and other information that may be
          available on or through your social media account consistent with your
          applicable settings and
        </p>
        <h2>1. instructions.</h2>
        <p>
          a. In creating an account and/or claiming your business' listing, you
          represent to us that all information provided to us in such process is
          true, accurate and correct, and that you will update your information
          as and when necessary in order to keep it accurate. If you are
          creating an account or claiming a business listing, then you represent
          to us that you are the owner or authorized agent of such business. You
          may not impersonate someone else, create or use an account for anyone
          other than yourself, provide an email address other than your own,
          create multiple accounts or business listings except as otherwise
          authorized by us, or provide or use false information to obtain access
          to a business' listing on the Services that you are not legally
          entitled to claim. You acknowledge that any false claiming of a
          business listing may cause Terrachow or third parties to incur
          substantial economic damages and losses for which you may be held
          liable and accountable.
        </p>
        <p>
          b. You are also responsible for all activities that occur in your
          account. You agree to notify us immediately of any unauthorized use of
          your account in order to enable us to take necessary corrective
          action. You also agree that you will not allow any third party to use
          your Terrachow account for any purpose and that you will be liable for
          such unauthorized access. e. By creating an account, you agree to
          receive certain communications in connection with Terrachow Platform
          or Services. For example, you might receive comments from other
          Customers or other Customers may follow the activity to do on your
          account. You can opt-out or manage your preferences regarding
          non-essential communications through account settings.
        </p>
        <h3>2. Others Terms</h3>
        <ul>
          <li>
            You agree to use the Services only for purposes that are permitted
            by (a) the Terms and (b) any applicable law, regulation or generally
            accepted practices or guidelines in the relevant jurisdictions.
            </li>
            <li>
              You agree to use the data owned by Terrachow (as available on the
              Services or through any other means like API etc.) only for
              personal use/purposes and not for any commercial use (other than
              in accordance with 'Claim Your Business Listing' access) unless
              agreed to by/with Terrachow in writing.
            </li>
            <li>
              You agree not to access (or attempt to access) any of the Services
              by any means other than the
            </li>
        </ul>
        <p>
          interface that is provided by Terrachow, unless you have been
          specifically allowed to do so, by way of a separate agreement with
          Terrachow. You specifically agree not to access (or attempt to access)
          any of the Services through any automated means (including use of
          scripts or web crawlers) and shall ensure that you comply with the
          instructions set out in any robots.txt file present on the Services.
        </p>
        <p>
          e. You agree that you will not engage in any activity that interferes
          with or disrupts the Services (or the servers and networks which are
          connected to the Services). You shall not delete or revise any
          material or information posted by any other Customer(s), shall not
          engage in spamming, including but not limited to any form of emailing,
          posting or messaging that is unsolicited.
        </p>
        <h2>VIII. Cancellation and return policy</h2>
        <ul>
          <li>
            You acknowledge that (1) your cancellation, or attempted or
            purported cancellation of an Order or (2) cancellation due to
            reasons not attributable to Terrachow, that is, in the event you
            provide incorrect particulars, contact number, delivery address
            etc., or that you were unresponsive, not reachable or unavailable
            for fulfillment of the services offered to you, shall amount to
            breach of your unconditional and irrevocable authorization in favour
            of Terrachow to place that Order against the Restaurant
            Partners/Store(s) on your behalf (“Authorization Breach"). In the
            event you commit an Authorization Breach, you shall be liable to pay
            the liquidated damages of an amount equivalent to the Order Value.
            You hereby authorize Terrachow to deduct or collect the amount
            payable as liquidated damages through such means as Terrachow may
            determine in its discretion, including without limitation, by
            deducting such amount from any payment made towards your next Order
          </li>
          <li>
            There may be cases where Terrachow is either unable to accept your
            order or cancels the order, due to reasons including without
            limitation, technical errors, unavailability of the item(s) ordered,
            or any other reason attributable to Terrachow, Restaurant
            Partner/Store or Delivery Partner. In such cases, Terrachow shall
            not charge a cancellation charge from you. If the order is cancelled
            after payment has been charged and you are eligible for a refund of
            the Order Value or any part thereof, the said amount will be
            reversed to you.
          </li>
          <li>
            No replacement / refund / or any other resolution will be provided
            without Restaurant Partner’s/Store(s)’ permission.
          </li>
          <li>
            Any complaint, with respect to the Order which shall include
            instances but not be limited to food spillage, foreign objects in
            food, delivery of the wrong order or food and beverages or Products,
            poor quality, You will be required to share the proof of the same
            before any resolution can be provided.
          </li>
          <li>
            You shall not be entitled to a refund in case instructions placed
            along with the Order are not followed in the form and manner You had
            intended. Instructions are followed by the Restaurant Partner /Store
            on a best-efforts basis.
          </li>
          <li>
            All refunds shall be processed in the same manner as they are
            received, unless refunds have been provided to You in the form of
            credits, refund amount will reflect in your account based on
            respective banks policies.
          </li>
        </ul>
      </div>
    <Footer />

    </>
  );
}

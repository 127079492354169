import React, { useEffect, useState } from 'react'
import { Grid, GridRow, Icon, } from 'semantic-ui-react'
import Nav from '../../components/navbar/nav'
import layerOrder from '../../assets/images/whh_layerorder.png'
import flatui from '../../assets/images/flat-ui.png'
import cart from '../../assets/images/cart.png'
import RestaurantPartner from '../../components/homepages/RestaurantPartner'
import Footer from '../../components/homepages/Footer'
const Homepage = (props) => {

  return (
      <>
      
      <Nav {...props} />
       <div className="x-homepage">
       <div className="x-banner">
         
       <Grid style={{height: "100%"}}>
         <Grid.Row className="x-flex">
          <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="middle" className="x-left">
           <h1>All The Food You Need In One App </h1>
          <div className="x-banner-desc">
          It’s simple: we list your favourite physical and virtual restaurants menu on the app, you order your preferred items, we pick them up and deliver in a heartbeat.
          </div>
          
          <div className="store">
          <a href="https://apps.apple.com/us/app/terrachow/id1587526296" className="x-download" target="__blank">
              <img src={"https://res.cloudinary.com/dnltxw2jt/image/upload/v1731154161/ntsv2brfd8lboncvjavk.png"} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.terrachow.terrachow" className="x-download" target="__blank">
              <img src={"https://res.cloudinary.com/dnltxw2jt/image/upload/v1731153650/ibfyv6toskeeikywuuv5.webp"} />
            </a>
    
          </div>
         {/* <Grid>
         <Grid.Row >
          <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="middle" textAlign="center">
            <a className="x-download" href="">
              <img src={process.env.PUBLIC_URL + './images/appstore.png'} />
            </a>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
         
            </Grid.Column>
         </Grid.Row>
         </Grid> */}
          </Grid.Column>
          <Grid.Column className="x-right" mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
              <img className="x-right-thumbnail" src="https://res.cloudinary.com/dnltxw2jt/image/upload/v1733480927/donfzxcjk2pswwr1oc4l.png" />
          </Grid.Column>
         </Grid.Row>
         </Grid>
       </div>
       
       {/* <div className="x-footer">
         <p>Follow us on these social media platforms</p>
         <a href="https://twitter.com/terra_chow" >
           <Icon name="twitter" />
         </a>
         <a href="https://facebook.com/terrachow"  >
           <Icon name="facebook" />
         </a>
         <a href="https://instagram.com/terrachow" >
           <Icon name="instagram" />
         </a>
       </div> */}
       <div className='x-favour'>
         <div className='x-favourite'>
           <h5 className=''>How it work</h5>
           <h3>Your favorite food delivered in minutes</h3>
           
         </div>
       
       <div className='ui container x-how'>
         <Grid columns="equal" stackable verticalAlign='middle' centered  >
         <Grid.Row className='x-equal' >
        <Grid.Column centered >
        <div className="cen">
          <img src={layerOrder} />
        </div>
        <div>
          <h4 class="">Restaurant listing</h4>
          <p>
              Access nearby phsical and virtual 
              restaurants menu, select your 
              favourite dish and order
          </p>

        </div>
      </Grid.Column>
      <Grid.Column>
        <div className="cen">
        <img src= {cart} />

        </div>
        <div>
        <h4 class="">Order Processing</h4>
          <p>
              The restaurants accepts your order
              and process them in real-time
          </p>

        </div>
      </Grid.Column>
      <Grid.Column>
        <div className='cen'>
          <img src= {flatui} />

        </div>
        <div>
        <h4 class="">Optimized Delivery</h4>
          <p>
            Know where your order is at all times,
            from the restaurant to your doorstep
          </p>

        </div>
      </Grid.Column>
      </Grid.Row>
         </Grid>
       </div>
       </div>
       <RestaurantPartner />
       <Footer />
      
    </div>
   
    

  </>
      
  )
}


export default Homepage
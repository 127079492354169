import React, {Component} from "react";
import { Link } from "react-router-dom";
import { Button, Icon} from  "semantic-ui-react"
// import { ArrowRightOutlined, BarsOutlined } from "@ant-design/icons";
import $ from "jquery";
import Navmodal from "./navmodal";

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      toggleModal: false,
    };
  }
  componentDidMount() {
    $(window).scrollTop(0)
    
  }
  
  handleOpen = () => {
    this.setState({ active: true });
  };
  handleClose = () => {
    this.setState({ active: false, toggleModal: false });
  };
  toggleModal = () => {
    this.setState({ toggleModal: true });
  };
  scroll = (e,div) => {
    e.preventDefault()
    var { location, history } = this.props
    if(this.state.toggleModal) this.handleClose()
    if(location.pathname != '/'){
        history.push({
            pathname: '/',
            state: { scrollTo: div }})
    } else{
        $('html, body').animate({
            scrollTop: $(div).offset().top - 100
        }, 500);
    }
}
  render() {
    return (
      <>
        {this.state.toggleModal ? (
          <Navmodal
            {...this.props}
            handleClose={this.handleClose}
            logout={this.logout}
            scroll={this.scroll}
          />
        ) : null}
        <nav className="x-nav">
          <ul>
            <li>
              <Link to="/">
                <img className="x-logo" src={process.env.PUBLIC_URL + "/images/logo-trans.png"} />
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy policy</Link>
            </li>
            <li>
              <Link to="/tos">Terms & condition</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            {/* <li>
              <Link to="/" onClick={(e)=>this.scroll(e, '#about')}>About us</Link>
            </li> */}
            {/* <li>
              <Link to="/housing">Housing</Link>
            </li> */}
            {/* <li>
              <Link to="/estate">Estate</Link>
            </li> */}
            {/* <li>
              <Link to="/investment">Investment</Link>
            </li> */}

            <li className="x-mobile">
              <Button color="youtube" onClick={this.toggleModal}>
                <Icon  name="bars" />
              </Button>
            </li>
            {/* <li>
              <Button
                size="large"
                onClick={() => this.props.history.push("/contact")}
              >
                Contact Us
                <ArrowRightOutlined />
              </Button>
            </li> */}
          </ul>
        </nav>
      </>
    );
  }
}

import Axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { Button, Form, FormField, Grid, Input, Table, TableHeader } from "semantic-ui-react";
import moment from "moment"
import { numberWithCommas, unique } from "../../store/utils";
export default function Pricing(props) {
    const [data, setData] = useState([])
    const [cus, setCus] = useState([])
    const [vendor, setVendor] = useState([])
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')
  
    useEffect(() => {
        var token = window.localStorage.getItem('token')
        if (!token) {
            var password = prompt()
            Axios.post(`https://terrachow.com/api/auth/signin`, { email: 'devhabeeb@gmail.com', password, userType: 'user', method: 'Form' }).then((suc) => {
                setToken(suc.data.token)
                window.localStorage.setItem('token', suc.data.token)
            }).catch(err => console.log(err))
        } else {
            setToken(token)
        }
    }, [])
    const fetch = () => {
        setLoading(true)
        Axios.get(`https://terrachow.com/api/foods/pricing?pricing=${text}`, { headers: { 'authorization':  token } }).then((suc) => {
            setData(suc.data.foods)
            setCus(suc.data.customizations)
            setVendor(suc.data.vendor)
             setLoading(false) 
        })
            .catch(err => console.log(err))
    }
    const updateFood = (food, type) => {
        var price = prompt()
        Axios.post(`https://terrachow.com/api/foods/${type == 'food'? 'updateFoodPrice': "updateCusPrice"}`, { price , foodId: food._id}, { headers: { 'authorization':  token } }).then((suc) => {
        fetch()
        }).catch(err => console.log(err))
    }
 
    const logout = () => {
        window.localStorage.clear()
        window.location.reload()
}
    return (
        <div className="history" style={{ padding: '2em' }}>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column mobile={8} computer={5}>
                        <Form  onSubmit={fetch}  >
                            <FormField
                                control='input'
                                placeholder='First name'
                                onChange={(e)=>setText(e.target.value)}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column mobile={4} computer={2} style={{padding: 0}}>
                    <Button color="google plus" onClick={fetch} loading={loading} type='submit' fluid style={{ marginTop: '0em' }}>Submit</Button> 
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table striped size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        {/* <Table.HeaderCell>Email </Table.HeaderCell> */}
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Vendor</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data?.map(d => (
                        <Table.Row>
                                <Table.Cell width={2}>
                              Food
                            </Table.Cell>
                            <Table.Cell width={4}>
                                <div>{d.title} </div>
                            </Table.Cell>
                            {/* <Table.Cell width={2}>{d.userId.email} <br /> {d.userId.phone}</Table.Cell> */}
                            <Table.Cell width={2}>
                            <div>{d.price } <small>( <span onClick={()=>updateFood(d, 'food')} style={{color: "red", cursor: "pointer"}}>Edit</span> )</small></div>
                            </Table.Cell>
                            <Table.Cell width={3}>
                            <div>{vendor.title }</div>
                            </Table.Cell>
                            <Table.Cell width={3}>
                            <div>{vendor?.address?.city }</div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {cus?.map(d => (
                        <Table.Row>
                              <Table.Cell width={2}>
                              Customization
                            </Table.Cell>
                            <Table.Cell width={4}>
                                <div>{d.title} </div>
                            </Table.Cell>
                            {/* <Table.Cell width={2}>{d.userId.email} <br /> {d.userId.phone}</Table.Cell> */}
                            <Table.Cell width={2}>
                            <div>{d.price } <small>( <a href="#" onClick={()=>updateFood(d, 'cus')} style={{color: "red"}}>Edit</a> )</small></div>
                            </Table.Cell>
                            <Table.Cell width={3}>
                            <div>{vendor.title }</div>
                            </Table.Cell>
                            <Table.Cell width={3}>
                            <div>{vendor?.address?.city }</div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <div style={{textAlign: "right"}}> 
           <Button onClick={logout} color="google plus" compact size="tiny">Logout</Button>
           </div>
        </div>
    )
}
